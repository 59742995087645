import React, { useContext, useEffect, useRef, useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Form, PrimaryButton, TertiaryButton, ButtonIcon } from '@hz-design-system/web-ui';

import CarsSearchFormDropDown from './CarsSearchFormDropDown';
import CarsSearchFormToggleGroup from './CarsSearchFormToggleGroup';
import CarsContinueSearch from './CarsContinueSearch';
import Classes from './CarsSimpleSearch.scss';

import { TCarsSearchFieldsClient, TFormState } from 'types/TCarsSearchFields';
import EnvironmentContext from '../../../contexts/EnvironmentContext';

type TCarsSimpleSearchProps = {
  formFields: TCarsSearchFieldsClient;
  resultCount?: number;
  onSubmit: (e?: React.MouseEvent<HTMLElement>) => void;
  onChange: Function;
  toggleAdvancedSearch: Function;
  formState: TFormState;
  simpleSearchKeys: string[];
  lastSearch?: TSearchEntry;
};

const AMOUNT_OF_FIELDS_FOR_3_COLUMN_LAYOUT = 6;
let CSSTransition;
let SwitchTransition;

const CarsSimpleSearch = ({
  formFields,
  resultCount,
  onSubmit,
  onChange,
  toggleAdvancedSearch,
  formState,
  simpleSearchKeys,
  lastSearch,
}: TCarsSimpleSearchProps) => {
  const { t } = useI18nContext();
  const {
    links: { carsSearchBaseUrl },
  } = useContext(EnvironmentContext);
  const [transitionsLoaded, setTransitionsLoaded] = useState(false);
  const [state, setState] = useState(true);
  const helloRef = useRef<any>(null);
  const goodbyeRef = useRef<any>(null);
  const nodeRef = state ? helloRef : goodbyeRef;

  useEffect(() => {
    setState((hop) => !hop);
  }, [resultCount]);

  useEffect(() => {
    (async () => {
      ({ CSSTransition, SwitchTransition } = await import(
        /* webpackChunkName: "reactTransitionGroup" */ 'react-transition-group'
      ));
      setTransitionsLoaded(true);
    })();
  }, []);

  const renderSearchComponent = (fieldKey: string) => {
    if (fieldKey === 'l2CategoryId') {
      return (
        <CarsSearchFormDropDown
          key="l2CategoryId"
          fieldKey="l2CategoryId"
          formField={formFields['l2CategoryId']}
          value={formState['l2CategoryId']}
          onChangeHandler={onChange}
          withoutLabel
          fixedField
        />
      );
    }

    if (['brand', 'model'].includes(fieldKey)) {
      return (
        <CarsSearchFormDropDown
          key={fieldKey}
          fieldKey={fieldKey}
          formField={formFields[fieldKey]}
          value={formState['attributes']?.[fieldKey]?.[0]}
          disabled={!formFields[fieldKey]}
          onChangeHandler={onChange}
          withoutLabel
          fixedField
        />
      );
    }

    if (['transmission', 'fuel'].includes(fieldKey)) {
      return (
        <CarsSearchFormDropDown
          key={fieldKey}
          fieldKey={fieldKey}
          formField={formFields[fieldKey]}
          value={formState['attributes']?.[fieldKey]?.[0]}
          disabled={!formFields[fieldKey]}
          onChangeHandler={onChange}
          withoutLabel
          fixedField
        />
      );
    }

    if (fieldKey === 'priceType') {
      return (
        <CarsSearchFormToggleGroup
          key="priceType"
          fieldKey="priceType"
          formField={formFields['priceType']}
          values={formState['attributes']?.['priceType']}
          onChangeHandler={onChange}
          withoutLabel
        />
      );
    }

    if (fieldKey === 'PriceCents') {
      return (
        <CarsSearchFormDropDown
          key={`${fieldKey}.to`}
          fieldKey={`${fieldKey}.to`}
          formField={formFields[fieldKey]}
          value={formState['rangeAttributes']?.[fieldKey]?.to}
          onChangeHandler={onChange}
          optionalText={t('cars.search.fields.rangeMaximum')}
          withoutLabel
          fixedField
        />
      );
    }

    if (fieldKey === 'batteryChargeTime') {
      return (
        <CarsSearchFormDropDown
          key={`${fieldKey}.to`}
          fieldKey={`${fieldKey}.to`}
          formField={formFields[fieldKey]}
          options={formFields[fieldKey]?.optionsTo}
          value={formState['rangeAttributes']?.[fieldKey]?.to}
          onChangeHandler={onChange}
          optionalText={t('cars.search.fields.rangeMaximum')}
          withoutLabel
          fixedField
        />
      );
    }

    if (['constructionYear', 'range', 'batteryCapacity'].includes(fieldKey)) {
      return (
        <CarsSearchFormDropDown
          key={`${fieldKey}.from`}
          fieldKey={`${fieldKey}.from`}
          formField={formFields[fieldKey]}
          options={formFields[fieldKey]?.optionsFrom}
          value={formState['rangeAttributes']?.[fieldKey]?.from}
          onChangeHandler={onChange}
          optionalText={t('cars.search.fields.rangeFromUpward')}
          withoutLabel
          fixedField
        />
      );
    }
  };

  return (
    <Form onSubmit={onSubmit} action={carsSearchBaseUrl} method="get">
      <div
        className={`SimpleFormFields ${Classes.SimpleFormFields} ${
          simpleSearchKeys.length === AMOUNT_OF_FIELDS_FOR_3_COLUMN_LAYOUT ? 'MoreFields' : ''
        }`}
      >
        {simpleSearchKeys.map((fieldKey) => renderSearchComponent(fieldKey))}
      </div>

      <div className={Classes.FormButtons}>
        {lastSearch && <CarsContinueSearch searchEntry={lastSearch} />}
        <TertiaryButton className={`${Classes.FormButton} AdvancedSearchLink`} onClick={(e) => toggleAdvancedSearch(e)}>
          <ButtonIcon name="plus" isStartIcon />
          {t('cars.search.actions.advancedSearch')}
        </TertiaryButton>
        <div className={Classes.SubmitButtonContainer}>
          <PrimaryButton className={Classes.SubmitButton} type="submit">
            <ButtonIcon name="search" isStartIcon />
            {transitionsLoaded && (
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={resultCount}
                  nodeRef={nodeRef}
                  classNames="spring"
                  addEndListener={(done: () => void) => {
                    nodeRef.current?.addEventListener('transitionend', done, false);
                  }}
                >
                  <span className="countLabel" ref={nodeRef}>
                    {t('cars.search.actions.submitSearchWithCount', { resultCount })}
                  </span>
                </CSSTransition>
              </SwitchTransition>
            )}
          </PrimaryButton>
        </div>
      </div>
    </Form>
  );
};

export default CarsSimpleSearch;
