import React from 'react';
import { Title4, Title5 } from '@hz-design-system/web-ui';

import Classes from './ContentArticles.scss';

type TContentArticleHtmlProps = {
  contentKey?: string;
};

const ContentArticleHtml = ({ contentKey }: TContentArticleHtmlProps) => {
  switch (contentKey) {
    case 'article-1':
      return (
        <>
          <Title4 className={Classes.modalTitle}>
            Waar moet je op letten bij de aankoop van een tweedehands elektrische auto?
          </Title4>
          <p>
            Het aanbod van tweedehands elektrische auto's groeit gestaag, maar hoe weet je of de batterij nog in goede
            staat verkeert? En welke auto past het beste bij jouw rijgedrag?
          </p>
          <p>
            De markt voor gebruikte elektrische auto's begint te groeien, vooral modellen die na 2017 zijn uitgebracht,
            zijn nu beschikbaar als occasions. Ondanks dat de ontwikkeling van elektrische auto's de afgelopen tien jaar
            snel is gegaan, zijn dit vaak nog steeds betrouwbare voertuigen. Elektrische auto's vereisen over het
            algemeen minder onderhoud dan brandstofauto's omdat ze minder bewegende onderdelen hebben die kunnen
            slijten.
          </p>
          <Title5>Controleer de batterij</Title5>
          <p>
            Het belangrijkste en duurste onderdeel van een elektrische auto is de batterij. De grootte van de batterij
            bepaalt immers hoe ver je kunt rijden met de elektrische auto (actieradius of bereik). Na verloop van tijd
            kunnen oplaadbare batterijen echter minder elektriciteit opslaan en leveren, zelfs als ze niet worden
            gebruikt. Dit wordt batterijdegradatie genoemd. Ook de batterij van een elektrische auto neemt af in
            vermogen naarmate deze ouder wordt, gemiddeld met ongeveer 2% per jaar.
          </p>
          <Title5>Tips voor het controleren van de batterij</Title5>
          <ul>
            <li>
              Maak een uitgebreide proefrit met de auto die je overweegt te kopen. Rijd het liefst de accu helemaal leeg
              en noteer hoeveel kilometer je hebt afgelegd. Vergelijk dit met de oorspronkelijke actieradius van de auto
              toen deze nieuw was. Deze informatie is te vinden op websites zoals ev-database.nl. Let hierbij goed op de
              'Real Range' en de weersomstandigheden, aangezien dit het realistische bereik van de auto aangeeft. Het
              verschil tussen de gereden kilometers en het oorspronkelijke bereik kan iets zeggen over degradatie van de
              batterij.
            </li>
            <li>
              Als je niet genoeg tijd hebt om de accu volledig leeg te rijden, maak dan een proefrit van ongeveer 50
              kilometer waarbij je verschillende soorten wegen berijdt, zoals stadsverkeer, snelwegen en regionale
              wegen. Als de actieradius met meer dan 75 kilometer is afgenomen, kan dit duiden op een mogelijke
              problematische batterij.
            </li>
            <li>
              Stop tegen het einde van de rit bij een snellaadstation en test hoe snel de auto oplaadt. Je kunt hiervoor
              referentiepunten gebruiken, zoals laadtijden die vermeld staan in advertenties of handleidingen van
              vergelijkbare modellen. De laadtijd moet redelijk overeenkomen met deze referenties. Als de laadtijd twee
              keer (of langer) duurt dan wat normaal zou moeten zijn, is dat iets om in gedachten te houden.
            </li>
            <li>
              Let op: sommige oudere modellen ondersteunen geen snelladen. Houd er ook rekening mee dat oudere modellen
              mogelijk een andere stekkeraansluiting hebben dan standaard tegenwoordig, waardoor ze mogelijk niet
              compatibel zijn met alle laadpalen. Dit geldt bijvoorbeeld voor de Nissan Leaf en oudere modellen van de
              Renault Zoë.
            </li>
            <li>
              Ontdek of er nog garantie is op het batterijpakket en hoe lang deze geldig is. De garantieperiode varieert
              per fabrikant, maar meestal bedraagt deze 8 tot 10 jaar of een bepaald aantal gereden kilometers, zoals
              160.000 tot 180.000 kilometer.
            </li>
            <li>
              Controleer ook of de laadkabel wordt meegeleverd (deze hoort standaard bij elk elektrisch voertuig) en
              controleer of je thuis, of in de buurt van je huis kunt opladen.
            </li>
          </ul>
          <Title5>Vraag aan de vorige eigenaar</Title5>
          <p>
            Net als bij tweedehands auto's die op fossiele brandstoffen rijden, is het verstandig om bij een tweedehands
            elektrische auto te informeren naar het gebruik door de vorige eigenaar. Het gebruik heeft namelijk invloed
            op de kwaliteit en levensduur van de batterij. Stel vragen over:
          </p>
          <ul>
            <li>
              Hoe vaak heeft de vorige eigenaar de auto opgeladen bij een snellader? Regelmatig opladen via snelladers
              kan nadelig zijn voor de batterij. Idealiter wil je dat dit minder dan één keer per week gebeurde.
            </li>
            <li>Is de batterij regelmatig ontladen tot onder 20% of juist volledig opgeladen tot boven 80%?</li>
            <li>
              Waar werd de auto meestal geparkeerd? Stond deze vaak buiten? En zo ja, stond hij dan vaak in direct
              zonlicht? Warmte is niet goed voor de batterij en kan een extra vermindering van de capaciteit
              veroorzaken.
            </li>
            <li>
              Stond de auto vaak volledig opgeladen of juist bijna leeg geparkeerd? Een elektrische auto voelt zich het
              beste bij ongeveer 40-70% lading, wat helpt om de levensduur van de batterij te verlengen.
            </li>
          </ul>
          <Title5>Kosten van een tweedehands elektrische auto</Title5>
          <p>
            Over het algemeen zijn tweedehands elektrische auto's iets duurder dan vergelijkbare gebruikte
            brandstofauto's. Maar door het groeiende aanbod zullen ze waarschijnlijk steeds aantrekkelijker worden qua
            prijs. Bovendien zijn er regelmatig subsidies beschikbaar voor gebruikte elektrische auto's. De specifieke
            voorwaarden hiervoor kun je vinden bij het SEPP-loket van RVO:
            https://www.rvo.nl/subsidies-financiering/sepp/subsidie-koop-gebruikte
          </p>
          <Title5>Garantie voor een tweedehands elektrische auto</Title5>
          <p>
            Fabrikanten hebben onderling afgesproken om 8 jaar garantie te bieden op de batterij of tot er 160.000
            kilometer is gereden (afhankelijk van welk criterium als eerste wordt bereikt). Deze garantie houdt vaak in
            dat de batterij wordt vervangen als deze binnen die periode minder dan 70% van zijn oorspronkelijke vermogen
            heeft.
          </p>
        </>
      );
    case 'article-2':
      return (
        <>
          <Title4 className={Classes.modalTitle}>
            Wat is het kostenverschil tussen elektrische auto's en auto's die op benzine of diesel rijden?
          </Title4>
          <p>
            Een nieuwe elektrische auto, waarmee je jaarlijks 15.000 kilometer rijdt gedurende vier jaar, kost gemiddeld
            0,57 euro per kilometer. Dit omvat de aankoopprijs, het onderhoud, de elektriciteit, belastingen en
            afschrijving. Voor een vergelijkbare auto die op brandstof rijdt, betaal je 2 tot 4 cent meer per kilometer.
            Hoewel de aanschaf van een elektrische auto duurder is, zijn het gebruik en onderhoud ervan goedkoper. De
            totale kosten worden ook wel total cost of ownership (TCO) genoemd en kunnen variëren afhankelijk van het
            merk, model en het aantal kilometers dat je jaarlijks rijdt. Dankzij de huidige regelgeving van de overheid
            zijn de TCO-kosten van veel elektrische auto's ongeveer gelijk aan of lager dan die van een vergelijkbare
            brandstofauto.
          </p>
          <p>
            Bij de aanschaf van een elektrische auto hoef je tot 2025 geen Belasting van Personenauto's en
            Motorrijwielen (BPM) en Motorrijtuigenbelasting (MRB) te betalen. Sinds 2020 zijn er ook subsidies
            beschikbaar via de nationale overheid in de vorm van de SEPP-regeling.
          </p>
          <p>
            Ondanks deze tegemoetkomingen is het kopen van een nieuwe elektrische auto meestal nog steeds iets duurder
            dan een vergelijkbare brandstofauto. Met name in het A-, B- en C-segment, waar de prijzen van elektrische
            auto's aanzienlijk hoger liggen dan die van vergelijkbare brandstofauto's. Dit wordt veroorzaakt door
            verschillende factoren:
          </p>
          <ul>
            <li>De kosten van batterijen en gerelateerde technologieën,</li>
            <li>Batterij temperatuur en management systemen</li>
            <li>Nog niet volledig ontwikkelde massaproductie.</li>
          </ul>
          <p>
            Gelukkig dalen de kosten voor batterijen en gerelateerde technologieën continu, waardoor de prijsverschillen
            tussen fossiele brandstofauto's en elektrische auto's steeds kleiner worden. De vraag naar dieselauto's in
            Nederland is bijvoorbeeld sterk afgenomen; in de eerste helft van 2023 was slechts 1,1% van de nieuw
            verkochte auto's een dieselvoertuig. Daarentegen groeit de vraag naar tweedehands elektrische auto's
            gestaag, wat ook invloed kan hebben op de prijs. Over het algemeen zijn de gebruikskosten van een
            elektrische auto echter veel lager dan die van een brandstofauto.
          </p>
          <p>
            Elektromotoren verbruiken namelijk 75% minder energie dan brandstofmotoren. Bij brandstofmotoren gaat 70-75%
            van de energie verloren in warmte. Dankzij deze efficiëntie heb je veel minder energie (elektriciteit) nodig
            om dezelfde afstand af te leggen in vergelijking met een brandstofvoertuig. Bovendien heeft een elektrische
            auto minder bewegende onderdelen, waardoor er minder reparaties, onderhoud en bandenwissels nodig zijn. Tot
            2025 hoef je ook geen Motorrijtuigenbelasting (MRB) te betalen voor een elektrische auto, en er is geen BPM
            verschuldigd.
          </p>
          <p>
            Het onderhoud van een elektrische auto is naar verwachting aanzienlijk goedkoper dan dat van een
            vergelijkbare brandstofauto, omdat verbrandingsmotoren veel meer bewegende onderdelen hebben die slijten en
            regelmatig onderhoud vereisen of vervangen moeten worden. Aangezien een elektrische auto maar weinig
            bewegende onderdelen heeft, is het onderhoud minimaal en bespaar je dus geld op deze kosten.
          </p>
          <p>
            Je hoeft bijvoorbeeld nooit olie te verversen, nieuwe bougies of koppelingen te vervangen bij een
            elektrische auto. Deze zaken zul je dan ook niet tegenkomen op de rekening voor onderhoud bij de garage. De
            banden en remmen slijten natuurlijk wel, maar over het algemeen gaan de remmen van een elektrische auto
            langer mee dan die van een benzine- of dieselauto, mede dankzij het gebruik van de elektromotor om te
            remmen.
          </p>
        </>
      );
    case 'article-3':
      return (
        <>
          <Title4 className={Classes.modalTitle}>8 voordelen van elektrisch rijden</Title4>
          <p>
            Elektrisch rijden heeft verschillende voordelen. Een volledig elektrische auto maakt geen gebruik van
            fossiele brandstoffen, waardoor het energiezuiniger is en er geen schadelijke uitlaatgassen vrijkomen.
            Daarnaast zijn er nog andere voordelen:
          </p>
          <ol>
            <li>
              Lagere energiekosten: Elektrisch rijden is over het algemeen goedkoper dan rijden op benzine of diesel.
            </li>
            <li>
              Subsidies: Voor sommige elektrische auto's zijn er subsidies beschikbaar, wat de kosten verder kan
              verlagen.
            </li>
            <li>
              Minder afhankelijkheid van olie of brandstof: Elektrische auto's hebben geen benzine of diesel nodig,
              waardoor je minder afhankelijk bent van fossiele brandstoffen.
            </li>
            <li>
              Sneller optrekken: Elektrische auto's hebben vaak een snelle acceleratie, waardoor je sportiever kunt
              rijden als je dat wilt.
            </li>
            <li>Geluidloos: Elektrische auto's maken nauwelijks geluid, wat zorgt voor minder geluidsoverlast.</li>
            <li>
              Onderhoudsvriendelijk: Elektromotoren in elektrische auto's zijn grotendeels onderhoudsvrij, wat betekent
              dat je minder tijd en geld hoeft te besteden aan regulier onderhoud.
            </li>
            <li>
              Geschikt voor Nederland: Nederland is een land met veel laadpalen en relatief korte afstanden, waardoor
              elektrisch rijden hier goed mogelijk is.
            </li>
            <li>
              Toegang tot binnensteden en milieuzones: Met een elektrische auto mag je elke binnenstad en milieuzone
              inrijden zonder beperkingen.
            </li>
          </ol>
          <p>
            Kortom, elektrisch rijden biedt vele voordelen en is een positieve keuze voor zowel het milieu als je
            portemonnee.
          </p>
        </>
      );
    case 'article-4':
      return (
        <>
          <Title4 className={Classes.modalTitle}>
            Hoeveel bereik heb je nodig voor je elektrische auto en hoe kun je dat bereik verbeteren?
          </Title4>
          <p>
            Het gemiddelde bereik van een elektrische middenklasser is momenteel ongeveer 310 kilometer. In Nederland
            zijn er al meer dan 100.000 oplaadpunten beschikbaar, dus het vinden van een laadpaal is geen probleem.
          </p>
          <p>
            Voor de meeste autoritten in Nederland is een bereik van 310 kilometer ruim voldoende, aangezien 9 van de 10
            ritten korter zijn dan 100 kilometer. Als je toch tussendoor moet opladen, kun je gebruikmaken van de vele
            openbare oplaadpunten en snellaadstations langs de snelwegen. Het aantal laadpunten blijft ook groeien, dus
            er is altijd wel een plek om bij te laden.
          </p>
          <p>Als je wilt zorgen voor een betere actieradius, hebben we een aantal handige tips voor je:</p>
          <ol>
            <li>
              Rijd rustig en gelijkmatig: Door rustig op te trekken, het gaspedaal los te laten om vaart te minderen en
              iets langzamer te rijden op de snelweg (bijvoorbeeld 100 in plaats van 120 km/u), bespaar je tot wel 30%
              aan bereik.
            </li>
            <li>
              Gebruik de cruise control: Hiermee kun je constant rijden zonder veelvuldig te remmen of optrekken, wat
              weer gunstig is voor het energieverbruik.
            </li>
            <li>
              Maak gebruik van eco-standen: Veel auto's hebben een eco-stand waarmee ze zuiniger rijden. Dit kan leiden
              tot energiebesparing tussen de 5% en 10%.
            </li>
            <li>
              Rem op de motor: Door het pedaal los te laten en lichtjes de rem in te trappen, kun je energie terugwinnen
              tijdens het remmen. Dit draagt bij aan een beter bereik. Let wel op dat je niet te hard remt, anders wordt
              er minder energie teruggewonnen.
            </li>
            <li>
              Beheers het gebruik van airco en verwarming: Het gebruik van airconditioning en verwarming kan invloed
              hebben op je bereik. Je kunt ervoor kiezen om de auto alvast te koelen of op te warmen terwijl deze nog
              aan de lader is, zodat je geen batterijvermogen gebruikt.
            </li>
            <li>
              Verwijder onnodige bagage: Als je geen dakkoffer, imperiaal of fietsendrager nodig hebt, haal ze dan van
              het dak af. Dit kan het verbruik met 5-15% verminderen.
            </li>
            <li>
              Controleer regelmatig bandenspanning: Zorg ervoor dat je banden elke 2 maanden goed zijn opgepompt. Dit
              zorgt niet alleen voor een veiligere rit, maar ook voor een lager energieverbruik en een langere
              levensduur van de banden.
            </li>
          </ol>
          <p>
            Met deze tips kun je jouw actieradius optimaliseren en genieten van een efficiëntere rit met jouw
            elektrische auto!
          </p>
        </>
      );
    default:
      return null;
  }
};

export default ContentArticleHtml;
