import React from 'react';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import FaqList from './components/FaqList';

import { TEnvConfig } from 'types/TServerContext';

type TFaqListAppProps = {
  environment: TEnvConfig;
};

const FaqListApp = ({ environment }: TFaqListAppProps) => (
  <EnvironmentProvider value={environment}>
    <FaqList />
  </EnvironmentProvider>
);

export default FaqListApp;
