import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Header } from '@hz-design-system/web-ui';

import CarsSearchApp from '../../apps/CarsSearch/CarsSearch';
import SellBlockApp from '../../apps/SellBlock/SellBlockApp';
import CarsCategoryLinksApp from '../../apps/CarsCategoryLinks/CarsCategoryLinksApp';
import BannersApp from '../../apps/Banners/Banners';
import PageBackGroundApp from '../../apps/PageBackground/PageBackGroundApp';

import { trackGAEvent } from '../../utils/gaTracking';

import '../../../scss/l1CarsPage.scss';
// server side only components styles for this page
import '../../../../server/pages/l1CarsPage.styles';
import CarsPageContentApp from '../../apps/CarsPageContent/CarsPageContent';

// eslint-disable-next-line require-await
export default async (i18n) => {
  // eslint-disable-next-line no-underscore-dangle
  const appState = window.__CONFIG__ || {
    carsCategoryLinks: [],
  };
  const environment = window.__ENV_CONFIG__ || {};
  const headerConfig = window.__HEADER_CONFIG__ || {};
  const headerRoot = document.getElementById('header-root');
  const backgroundRoot = document.getElementById('background-root');
  const bannerRoot = document.getElementById('banner-root');
  const carsSearchFormRoot = document.getElementById('cars-search-form-root');
  const sellBlockRoot = document.getElementById('sell-block-root');
  const categoryLinksRoot = document.getElementById('category-links-root');
  const pageContentRoot = document.getElementById('page-content-root');

  // Header is server side rendered in express-react library
  hydrateRoot(headerRoot!, <Header headerConfig={headerConfig} trackGAEvent={trackGAEvent} />);

  if (appState.withBanners) {
    hydrateRoot(
      bannerRoot!,
      <I18nContext.Provider value={i18n}>
        <BannersApp id="banner-top-dt" environment={environment} l1Category={appState.l1Category} />
      </I18nContext.Provider>,
    );
  }

  hydrateRoot(backgroundRoot!, <PageBackGroundApp page="cars" />);

  hydrateRoot(
    carsSearchFormRoot!,
    <I18nContext.Provider value={i18n}>
      <CarsSearchApp
        l1Category={appState.l1Category}
        l2CategoryField={appState.l2CategoryField}
        carsSearchFields={appState.carsSearchFields}
        resultCount={appState.resultCount}
        userPostcode={appState.userPostcode}
        environment={environment}
      />
    </I18nContext.Provider>,
  );

  hydrateRoot(
    sellBlockRoot!,
    <I18nContext.Provider value={i18n}>
      <SellBlockApp envConfig={environment} withLicensePlateField={appState.withLicensePlateField} />
    </I18nContext.Provider>,
  );

  hydrateRoot(
    categoryLinksRoot!,
    <I18nContext.Provider value={i18n}>
      <CarsCategoryLinksApp carsCategoryLinks={appState.carsCategoryLinks} environment={environment} />
    </I18nContext.Provider>,
  );

  hydrateRoot(
    pageContentRoot!,
    <I18nContext.Provider value={i18n}>
      <CarsPageContentApp appState={appState} environment={environment} />
    </I18nContext.Provider>,
  );
};
