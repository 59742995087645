import React, { useContext } from 'react';
import {
  Card,
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionHeader,
  Text,
  Title4,
  MaskedLink,
  Image,
} from '@hz-design-system/web-ui';

import Classes from './FaqList.scss';
import VERLogo from '../../../../images/cars-page/VER-logo-name.webp';
import EnvironmentContext from '../../../contexts/EnvironmentContext';
import { getGATrackingAttrForSSR, useGATrackingForSSR } from '../../../utils/gaTracking';

function getStaticImageUrl(staticBaseUrl, imagePath) {
  // In Node.js® babel doesn't automatically prefix image path with base url, but webpack in browser does
  if (typeof window === 'undefined') {
    return `${staticBaseUrl}${imagePath}`;
  }

  return imagePath;
}

const FaqList = () => {
  const { staticBaseUrl } = useContext(EnvironmentContext);
  const partnerLogoUrl = getStaticImageUrl(staticBaseUrl, VERLogo);
  useGATrackingForSSR();

  return (
    <>
      <Title4 className={Classes.heading}>Je vragen beantwoord</Title4>
      <Card className={Classes.root}>
        <Accordion>
          <AccordionItem itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <AccordionHeader
              className={Classes.questionHeading}
              itemProp="name"
              {...getGATrackingAttrForSSR('Clicked', 'FaqItem-1')}
            >
              <Text.Large>Welke soorten elektrische auto's zijn er?</Text.Large>
            </AccordionHeader>
            <AccordionBody
              onClick={(event) => event.stopPropagation()}
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="hz-Text hz-Text--bodyLarge" itemProp="text">
                <p>Er zijn verschillende soorten elektrische auto’s:</p>
                <p>
                  <span className="hz-Text hz-Text--bodyLargeStrong">BEV:</span>
                  <br />
                  De meeste elektrische auto’s die nu en in de toekomst op de weg rijden, zijn ‘Battery Electric
                  Vehicles’. Een BEV is 100% elektrisch en heeft geen brandstofmotor, brandstoftank en uitlaat.
                </p>
                <p>
                  <span className="hz-Text hz-Text--bodyLargeStrong">HEV:</span>
                  <br />
                  Een HEV (Hybrid Electric Vehicle) wordt ook aangeduid als Full Hybrid. Een hybride auto maakt voor de
                  aandrijving gebruik van zowel een verbrandingsmotor (benzine, diesel of LPG) als een elektrische
                  motor. Een HEV kan niet worden opgeladen en heeft dus ook geen stekker en laadkabel. Alle
                  elektriciteit waarop gereden wordt, is lokaal opgewekt door middel van de aanwezige brandstof.
                </p>
                <p>
                  <span className="hz-Text hz-Text--bodyLargeStrong">PHEV:</span>
                  <br />
                  PHEV staat voor ‘Plug-in Hybrid Electric Vehicle’. Dit is een plug-in hybride auto waarbij een
                  brandstofmotor wordt gecombineerd met een elektromotor. De elektromotor wordt gevoed door een kleinere
                  accu dan bij een BEV, maar die je nog wel met een stekker moet opladen. Met een volgeladen accu is het
                  mogelijk om langdurig de brandstofmotor te ondersteunen voor een extra laag verbruik, of 100%
                  elektrisch te rijden over een afstand van ongeveer 50 kilometer, afhankelijk van het model.
                </p>
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <AccordionHeader
              className={Classes.questionHeading}
              itemProp="name"
              {...getGATrackingAttrForSSR('Clicked', 'FaqItem-2')}
            >
              <Text.Large>Hoe werkt laden van een elektrische auto?</Text.Large>
            </AccordionHeader>
            <AccordionBody
              onClick={(event) => event.stopPropagation()}
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="hz-Text hz-Text--bodyLarge" itemProp="text">
                <p>
                  Wil je thuis je auto opladen en heb je een eigen terrein of een oprit waar je je auto kunt parkeren,
                  dan kun je een laadpaal voor thuis aanschaffen. Je kunt ook laden bij openbare laadpalen. Hiervoor heb
                  je een laadpas nodig en de bij de auto meegeleverde laadkabel. Kan je auto ook snelladen, dan kunt u
                  ook een snellader bezoeken. Deze staan vaak op verzorgingsplaatsen langs de snelweg en op goed
                  bereikbare parkeerterreinen. Ook voor deze palen heb je een laadpas nodig. Op Marktplaats kun je
                  filteren op de (snel)laadtijd van een elektrische auto.
                </p>
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <AccordionHeader
              className={Classes.questionHeading}
              itemProp="name"
              {...getGATrackingAttrForSSR('Clicked', 'FaqItem-3')}
            >
              <Text.Large>Hoe werkt laden bij een publieke oplaadpaal?</Text.Large>
            </AccordionHeader>
            <AccordionBody
              onClick={(event) => event.stopPropagation()}
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="hz-Text hz-Text--bodyLarge" itemProp="text">
                <p>
                  Om je elektrische auto op straat op te kunnen laden heb je een laadkabel en een laadpas nodig. Je
                  parkeert de auto bij de laadpaal en kijkt of de lampjes op de paal groen zijn. Zijn ze rood, dan is er
                  een storing en kun je niet opladen. Je plugt de stekker van de oplaadkabel in de auto en in de
                  laadpaal en houd je laadpasje voor de aangegeven plek. De kabel vergrendelt en de lichtjes op de
                  laadpaal worden nu blauw. Klaar met laden? Dan ontgrendel je de kabel ook weer met je laadpas.
                </p>
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <AccordionHeader
              className={Classes.questionHeading}
              itemProp="name"
              {...getGATrackingAttrForSSR('Clicked', 'FaqItem-4')}
            >
              <Text.Large>Hoe ver kun je rijden met een elektrische auto?</Text.Large>
            </AccordionHeader>
            <AccordionBody
              onClick={(event) => event.stopPropagation()}
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="hz-Text hz-Text--bodyLarge" itemProp="text">
                <p>
                  De afstand die je met een volle batterij van een elektrische auto kunt rijden, heet actieradius. Dit
                  wordt ook wel bereik of range genoemd. De actieradius van een elektrische auto hangt af van 4 dingen:
                </p>
                <ul>
                  <li>De capaciteit van de batterij </li>
                  <li>De hoeveelheid energie die de auto nodig heeft om te rijden (verbruik)</li>
                  <li>Rijgedrag / Rijstijl</li>
                  <li>Weersomstandigheden</li>
                  <li>
                    Status van de auto (denk hierbij aan bandenspanning, maar ook aan het hebben van een dakkoffer, het
                    trekken van een caravan etc.)
                  </li>
                </ul>
                <p>Op Marktplaats kun je filteren op de geadverteerde actieradius van een elektrische auto.</p>
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <AccordionHeader
              className={Classes.questionHeading}
              itemProp="name"
              {...getGATrackingAttrForSSR('Clicked', 'FaqItem-5')}
            >
              <Text.Large>Wat zijn de kosten van elektrisch rijden?</Text.Large>
            </AccordionHeader>
            <AccordionBody
              onClick={(event) => event.stopPropagation()}
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="hz-Text hz-Text--bodyLarge" itemProp="text">
                <p>
                  Een nieuwe elektrische auto waarmee je vier jaar jaarlijks 15.000 kilometer rijdt, kost gemiddeld 0,57
                  euro per kilometer. Dat is een all-in-prijs. Er zit dus alles in: de koop, het onderhoud, de
                  elektriciteit, de belastingen en de afschrijving. Een brandstofauto is gemiddeld 2 tot 4 cent duurder
                  per kilometer. Bij een elektrische auto is de aankoop dus vaak duurder, maar het gebruik en het
                  onderhoud goedkoper.
                </p>
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <AccordionHeader
              className={Classes.questionHeading}
              itemProp="name"
              {...getGATrackingAttrForSSR('Clicked', 'FaqItem-6')}
            >
              <Text.Large>Hoe duurzaam is elektrisch rijden nu echt?</Text.Large>
            </AccordionHeader>
            <AccordionBody
              onClick={(event) => event.stopPropagation()}
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div className="hz-Text hz-Text--bodyLarge" itemProp="text">
                <p>
                  Op basis van eigen onderzoek van Milieu Centraal, waarbij de totale CO2 uitstoot van een 100%
                  elektrische auto werd berekend en vergeleken met die van een gemiddelde middenklasse benzineauto,
                  blijkt dat de uitstoot van een elektrische auto 30 tot 40% lager is dan die van een benzine- of
                  dieselauto. Hierbij werd rekening gehouden met de productie van de auto en de batterij, een totaal van
                  220.000 gereden kilometers en het gebruik van grijze stroom uit Nederland met een CO2-emissiefactor
                  van 0,475 kg CO2/kWh.
                </p>
              </div>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </Card>

      <Title4 className={Classes.heading}>Onze partners</Title4>
      <MaskedLink
        href="https://www.evrijders.nl/over-ver?utm_source=marktplaats&utm_medium=logo&utm_campaign=zoekmachinepilot"
        {...getGATrackingAttrForSSR('Clicked', 'Partner-footer')}
      >
        <Image className={Classes.partnerLogo} alt="VER logo" src={partnerLogoUrl} loading="lazy" />
      </MaskedLink>
    </>
  );
};

export default FaqList;
