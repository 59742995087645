import React, { Fragment, useState } from 'react';
import { Title4, BlockLink, Image, Icon, BasicModal, MaskedLink } from '@hz-design-system/web-ui';
import ContentArticle from './ContentArticle';
import ContentArticleHtml from './ContentArticleHtml';
import VERLogo from '../../../../images/cars-page/VER-logo.webp';

import Classes from './ContentArticles.scss';
import TContentArticles, { TContentArticle } from 'types/TContentArticles';
import { trackGAEvent } from '../../../utils/gaTracking';

type TContentArticlesProps = {
  contentArticles: TContentArticles;
};

const ContentArticles = ({ contentArticles }: TContentArticlesProps) => {
  const [modalContent, setModalContent] = useState<TContentArticle | null>(null);

  const onClick = (article: TContentArticle, contentId: string, articleId: string) => {
    trackGAEvent('Clicked', `${contentId}-${articleId}`);

    if (article.contentKey) {
      setModalContent(article);
    }
  };

  return (
    <div className={Classes.root}>
      {contentArticles.map(({ id, heading, articles }, indexOfRow) => (
        <div key={id} className={`${Classes.articleGroup} ${id}`}>
          <Title4 as="h2" className={Classes.heading}>
            {heading}
          </Title4>
          <div className={Classes.contentRow}>
            {articles.map((article, indexOfArticle) => (
              <Fragment key={article.title}>
                {Boolean(article.link) && (
                  <BlockLink
                    className={Classes.item}
                    href={article.link}
                    onClick={() => onClick(article, id, article.id)}
                  >
                    <ContentArticle article={article} />
                  </BlockLink>
                )}
                {!Boolean(article.link) && (
                  <BlockLink className={Classes.item} onClick={() => onClick(article, id, article.id)}>
                    <ContentArticle article={article} eagerLoadImage={indexOfRow === 0 && indexOfArticle <= 1} />
                  </BlockLink>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      ))}
      {Boolean(modalContent) && modalContent && (
        <BasicModal
          className={Classes.articleModal}
          title={modalContent.heading}
          size="m"
          onClose={() => setModalContent(null)}
        >
          {/* eslint-disable no-magic-numbers */}
          <Image
            className={Classes.modalImage}
            src={modalContent.images[3]}
            alt={modalContent.alt}
            srcSet={`${modalContent.images[2]} 530w, ${modalContent.images[3]} 2x`}
            loading="eager"
            decoding="sync"
          />
          <ContentArticleHtml contentKey={modalContent.contentKey} />
          <div className={Classes.partnerFooter}>
            <Image alt="VER logo" src={VERLogo} />
            <div className={Classes.partnerCopy}>
              <p>Informatie tot stand gekomen met partner</p>
              <MaskedLink
                href="https://www.evrijders.nl/over-ver?utm_source=marktplaats&utm_medium=banner&utm_campaign=zoekmachinepilot"
                onClick={() => trackGAEvent('Clicked', 'Partner-modal')}
              >
                Vereniging Elektrische Rijders
                <Icon name="external_link" color="button-primary-default" />
              </MaskedLink>
            </div>
          </div>
        </BasicModal>
      )}
    </div>
  );
};

export default ContentArticles;
