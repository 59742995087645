import React, { useState, useEffect } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { SecondaryButton, Icon } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

import { trackGAEvent } from '../../../utils/gaTracking';

import { TFacet } from 'types/TCarsSearchFields';

type TCarsSearchFormCheckboxGroupProps = {
  fieldKey: string;
  formField: TFacet;
  values?: string[];
  onChangeHandler: Function;
  isTenantSpecific?: boolean;
  withoutLabel?: boolean;
};

let BasicModal;

const CarsSearchFormToggleGroup = ({
  formField,
  fieldKey,
  values,
  onChangeHandler,
  withoutLabel,
}: TCarsSearchFormCheckboxGroupProps) => {
  const { t } = useI18nContext();
  const [leaseModalOpen, setLeaseModalOpen] = useState(false);

  useEffect(() => {
    if (!values) {
      return;
    }
  }, [values]);

  const leaseInfoOnClick = async () => {
    trackGAEvent('priceTypeTooltipClicked');
    try {
      BasicModal = (await import(/* webpackChunkName: "BasicModal" */ '@hz-design-system/web-ui')).BasicModal;
      setLeaseModalOpen(!leaseModalOpen);
    } catch (e) {}
  };

  if (!formField?.options?.length) {
    return null;
  }

  const handleOnClick = (event, option) => {
    onChangeHandler(fieldKey, option.value.toString(), formField?.type, true);
    event.target.blur();
  };

  return (
    <div className="FormField FormField--toggleGroup">
      {!withoutLabel && (
        <label className="FormField-Label hz-Text hz-Text--bodyLarge u-colorTextSecondary" htmlFor={fieldKey}>
          {formField.label}
          <Icon className="ModalTrigger" name="info" color="signal-action-default" onClick={leaseInfoOnClick} />
        </label>
      )}
      <div className="ToggleGroup-formControl">
        <SecondaryButton
          type="button"
          className={
            !values || values[0] === '' || !values.length || values.length === formField.attributeGroup?.length
              ? 'isActive'
              : ''
          }
          onClick={(e) => {
            handleOnClick(e, { value: '' });
          }}
        >
          {t('cars.search.options.all')}
        </SecondaryButton>
        {formField.options.map((option) => (
          <SecondaryButton
            type="button"
            className={values && values.length === 1 && values.indexOf(option.value.toString()) >= 0 ? 'isActive' : ''}
            onClick={(e) => {
              handleOnClick(e, option);
            }}
            key={option.value}
          >
            {t(`cars.search.options.${option.value}`, option.label)}
          </SecondaryButton>
        ))}
        {withoutLabel && (
          <Icon className="ModalTrigger" name="info" color="signal-action-default" onClick={leaseInfoOnClick} />
        )}
      </div>
      {leaseModalOpen && (
        <BasicModal
          size="m"
          title={t('cars.search.pricetype.modal_title')}
          className="PriceTypeModal"
          onClose={() => setLeaseModalOpen(false)}
        >
          <Trans
            i18nKey="cars.search.pricetype.modal"
            paragraph-open={Trans.safeString('<p>')}
            paragraph-close={Trans.safeString('</p>')}
            title-open={Trans.safeString('<h5>')}
            title-close={Trans.safeString('</h5>')}
            bold-open={Trans.safeString('<div class="hz-Text hz-Text--bodyLargeStrong">')}
            bold-close={Trans.safeString('</div>')}
            list-open={Trans.safeString('<ul>')}
            list-close={Trans.safeString('</ul>')}
            list-item-open={Trans.safeString('<li>')}
            list-item-close={Trans.safeString('</li>')}
            break={Trans.safeString('<br>')}
          />
        </BasicModal>
      )}
    </div>
  );
};

export default CarsSearchFormToggleGroup;
