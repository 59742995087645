import React from 'react';
import { Card, UtilsBlock, Button, Title5, Image } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import Classes from './ContentBlocks.scss';

type TContentBlocksProps = {
  contentArticles: string[][];
};

const INDEX_1X = 2;
const INDEX_2X = 3;

const ContentBlocks = ({ contentArticles }: TContentBlocksProps) => {
  const { t } = useI18nContext();

  return (
    <div className={Classes.root}>
      <div className={Classes.group}>
        <div className={Classes.contentBlock}>
          <Card padding="s">
            <Title5 className={Classes.title}>{t('cars.content.block1.title')}</Title5>
            <Image
              src={contentArticles[0][INDEX_1X]}
              alt=""
              srcSet={`${contentArticles[0][INDEX_1X]} 1x, ${contentArticles[0][INDEX_2X]} 2x`}
              loading="lazy"
              fetchpriority="low"
              decoding="async"
            />
            <div className={Classes.body}>
              <Trans i18nKey="cars.content.block1.main" />
            </div>
            <a href="https://www.marktplaats.nl/m/auto/wegwijzer/" className={Classes.button}>
              <Button.Secondary className={Classes.button}>{t('cars.content.block1.button')}</Button.Secondary>
            </a>
          </Card>
        </div>

        <div className={Classes.contentBlock}>
          <Card padding="s">
            <Title5 className={Classes.title}>{t('cars.content.block2.title')}</Title5>
            <Image
              src={contentArticles[1][INDEX_1X]}
              alt=""
              srcSet={`${contentArticles[1][INDEX_1X]} 1x, ${contentArticles[1][INDEX_2X]} 2x`}
              loading="lazy"
              fetchpriority="low"
              decoding="async"
            />
            <div className={Classes.body}>
              <Trans i18nKey="cars.content.block2.main" />
            </div>
            <a href="https://www.marktplaats.nl/m/auto/auto-verkopen/" className={Classes.button}>
              <Button.Secondary className={Classes.button}>{t('cars.content.block2.button')}</Button.Secondary>
            </a>
          </Card>
        </div>

        <div className={`${Classes.contentBlock} ${Classes.banner}`}>
          <UtilsBlock id="banner-mr-dt" />
        </div>
      </div>

      <div className={Classes.group}>
        <div className={Classes.contentBlock}>
          <Card padding="s">
            <Title5 className={Classes.title}>{t('cars.content.block4.title')}</Title5>
            <Trans
              i18nKey="cars.content.block4.main"
              linkClassName="hz-Link"
              link1={'https://statisch.marktplaats.nl/html/hubs/auto/pdf/checklistaankoopauto.pdf'}
              link2={'https://statisch.marktplaats.nl/html/hubs/auto/pdf/voorbeeldkoopovereenkomst.pdf'}
              link3={'https://statisch.marktplaats.nl/html/hubs/auto/pdf/kwitantievoorbetaling.pdf'}
            />
          </Card>
        </div>

        <div className={Classes.contentBlock}>
          <Card padding="s">
            <Title5 className={Classes.title}>{t('cars.content.block5.title')}</Title5>
            <Trans
              i18nKey="cars.content.block5.main"
              linkClassName="hz-Link"
              link1={'https://www.marktplaats.nl/m/auto/auto-verkopen/'}
              link2={'https://statisch.marktplaats.nl/html/hubs/auto/pdf/voorbeeldkoopovereenkomst.pdf'}
              link3={'https://statisch.marktplaats.nl/html/hubs/auto/pdf/kwitantievoorbetaling.pdf'}
            />
          </Card>
        </div>

        <div className={Classes.contentBlock}>
          <Card padding="s">
            <Title5 className={Classes.title}>{t('cars.content.block3.title')}</Title5>
            <Trans
              i18nKey="cars.content.block3.main"
              linkClassName="hz-Link"
              link1={'https://www.marktplaats.nl/m/auto/auto-verkopen/'}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ContentBlocks;
