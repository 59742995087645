import React from 'react';
import { Card, UtilsBlock, Text, Image } from '@hz-design-system/web-ui';

import Classes from './ContentArticles.scss';
import { TContentArticle } from 'types/TContentArticles';

type TContentArticleProps = {
  article: TContentArticle;
  eagerLoadImage?: boolean;
};

const ContentArticles = ({ article, eagerLoadImage = false }: TContentArticleProps) => {
  return (
    <Card className={Classes.itemCard}>
      <Image
        className={Classes.itemImage}
        src={article.images ? article.images[1] : article.image}
        alt={article.alt}
        srcSet={article.images ? `${article.images[0]} 180w, ${article.images[1]} 360w` : ''}
        loading={eagerLoadImage ? 'eager' : 'lazy'}
        fetchpriority={eagerLoadImage ? 'high' : 'low'}
        decoding="async"
      />
      <UtilsBlock p="s" pl="xs" pr="xs">
        <Text.Large as="strong" className={Classes.itemTitle}>
          {article.title}
        </Text.Large>
        {Boolean(article.title2) && <Text.Regular className={Classes.itemTitle2}>{article.title2}</Text.Regular>}
        {Boolean(article.contentKey) && <div className={`hz-Link ${Classes.itemLink}`}>Lees meer</div>}
      </UtilsBlock>
    </Card>
  );
};

export default ContentArticles;
