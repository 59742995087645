import React, { useEffect, useState, useRef } from 'react';

import backgroundImageX1 from '../../../../images/cars-page/cars-l1-image@1x.webp';
import backgroundImageX2 from '../../../../images/cars-page/cars-l1-image@2x.webp';
import backgroundImageEvX1 from '../../../../images/cars-page/cars-ev-image@1x.webp';
import backgroundImageEvX2 from '../../../../images/cars-page/cars-ev-image@2x.webp';
import getIsMobile from '../../../utils/isMobile';
import { VEHICLE_TYPE_EV } from '../../CarsSearch/constants/vehicleTypes';

import Classes from './PageBackground.scss';

type TPageBackgroundProps = {
  page: 'cars' | 'ev';
};

let CSSTransition;
let SwitchTransition;

const PageBackground = ({ page }: TPageBackgroundProps) => {
  const [pageKey, setPageKey] = useState(page);
  const [isMobile, setIsMobile] = useState<boolean | undefined>();
  const nodeRef = useRef<any>(null);

  useEffect(() => {
    (async () => {
      const mobile = getIsMobile();
      if (mobile === false) {
        ({ CSSTransition, SwitchTransition } = await import(
          /* webpackChunkName: "reactTransitionGroup" */ 'react-transition-group'
        ));
      }
      setIsMobile(getIsMobile());
    })();
  }, []);

  useEffect(() => {
    window.addEventListener('vehicle-type-changed', (event) => {
      const vehicleType = (event as CustomEvent).detail;
      setPageKey(vehicleType === VEHICLE_TYPE_EV ? 'ev' : 'cars');
    });
  }, []);

  if (isMobile || typeof isMobile === 'undefined') {
    return null;
  }

  return (
    <div className={Classes.root}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={pageKey}
          nodeRef={nodeRef}
          addEndListener={(done: () => void) => {
            nodeRef.current?.addEventListener('transitionend', done, false);
          }}
          classNames="fade"
        >
          <img
            alt=""
            src={pageKey === 'cars' ? backgroundImageX1 : backgroundImageEvX1}
            ref={nodeRef}
            srcSet={
              pageKey === 'cars'
                ? `${backgroundImageX1} 1x, ${backgroundImageX2} 2x`
                : `${backgroundImageEvX1} 1x, ${backgroundImageEvX2} 2x`
            }
          />
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default PageBackground;
