import React, { useEffect, useState } from 'react';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import Carousels from '../CarsCarousels/components/Carousels';
import BannerContainer from '../Banners/Banners';
import FeedsApp from '../FeedsApp';
import ContentBlocks from './components/ContentBlocks';
import ContentArticles from '../ContentArticles/components/ContentArticles';
import FaqListApp from '../FaqList/FaqListApp';
import { VEHICLE_TYPE_EV } from '../CarsSearch/constants/vehicleTypes';

import { TEnvConfig } from 'types/TServerContext';

type TCarsPageContentAppProps = {
  environment: TEnvConfig;
  appState: any;
};

const CarsPageContentApp = ({ environment, appState }: TCarsPageContentAppProps) => {
  const [isEvContent, setIsEvContent] = useState(environment.isEvPage);

  useEffect(() => {
    window.addEventListener('vehicle-type-changed', (event) => {
      const vehicleType = (event as CustomEvent).detail;
      setIsEvContent(vehicleType === VEHICLE_TYPE_EV);
    });
  }, []);

  return (
    <EnvironmentProvider value={environment}>
      {!isEvContent && (
        <>
          {appState.carousels && (
            <section className="Page-section Page-section--full-width carouselsSection" id="cars-carousels-root">
              <Carousels carousels={appState.carousels} favoriteAdIds={appState.favoriteAdIds} />
            </section>
          )}
          {appState.withBanners && (
            <section className="Page-section Page-section--full-width bannerSection" id="banner-root">
              <BannerContainer id="banner-top-dt" environment={environment} l1Category={appState.l1Category} />
            </section>
          )}
          <section className="Page-section Page-section--full-width feedsSection" id="feeds-root">
            <FeedsApp appState={appState} environment={environment} />
          </section>
          {appState.withContentBlocks && (
            <section className="Page-section Page-section--full-width contentSection">
              <ContentBlocks contentArticles={appState.contentArticles.cars} />
            </section>
          )}
        </>
      )}
      {isEvContent && (
        <>
          <section className="contentSection" id="content-articles-root">
            <ContentArticles contentArticles={appState.contentArticles.evs} />
          </section>
          <section className="faqSection" id="faq-root">
            <FaqListApp environment={environment} />
          </section>
        </>
      )}
    </EnvironmentProvider>
  );
  // })();
};

export default CarsPageContentApp;
